<template>
  <div>
    <Hero
      header='Choose your programming language'
      title="Select any language below to start learning!"
      size='h-450'
      mSize='mb-h-350'
    />
    <div class="flex-center-col bg-alt-white pt-25 pb-50">
      <span class="code">// Languages</span>
      <div class="comments grid grid-2-col mb-grid-1-col gap-30 mrl-10p mt-25">
        <a v-for="language in languages" @click="goTo(language.link)" :key="language.id" class="p-25 radius-25 bg-white cursor-pointer">
          <div class="flex-center-start">
            <img v-show="language.img !== ''" :src="language.img" class="w-40 h-40 mr-10 small-shadow radius-100">
            <span class="font-24">{{ language.title }}</span>
          </div>
          <p v-show="language.desc">{{ language.desc }}</p>
        </a>
        <a class="flex-center p-25 radius-25 bg-white">
          <span class="font-32">More coming soon...</span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import languages from '@/scripts/languages.js';
import Hero from '@/components/Hero.vue';
import { scrollTop } from '@/scripts/helper.js'

export default {
  components: {
    Hero
  },
  data: function() {
    return {
      languages
    }
  },
  methods: {
    goTo(path) {
      if (window.location.pathname !== path) {
        this.$router.push(path);
      }
    }
  },
  mounted: function() {
    document.title = 'Learn | Code with Aba';
    scrollTop();
  },
}
</script>

<style scoped>
.code {
  width: fit-content;
  margin: 0 auto;
}
.comments a {
  text-decoration: none;
  color: black;
}
</style>
