export default [
  {
    title: 'C#',
    link: '../learn/csharp',
    img: require('@/images/csharp.png'),
    desc: 'C# is used for website development (front end / back end), mobile applications, console applications, desktop applications & many more!'
  },
  {
    title: 'Codewars (Problems)',
    link: '../learn/codewars',
    img: require('@/images/codewars.svg'),
    desc: 'Codewars is an excellent tool for practising programming solutions. In this section I\'ve solved many problems and explained how I solved them.'
  }
];